export const NETWORK_MAX_CONNECTION = 6;

export const ONE_DAY = 1000 * 60 * 60 * 24;
export const RETIRE_DATE = 90;
export const POST_LIST_SIZE = 20;
export const HOUR_TO_MIN = 60;
export const DAY_TO_HOUR = 24;
export const WEEK_TO_DAY = 7;

export const KAKAO_SAFE_AREA = 15;
export const PYUNG_NUM = 3.306;

export const COMMUNITY_CATEGORY_TOTAL_PK = 0;
export const COMMUNITY_CATEGORY_HIT_PK = 100;

export const MAX_BROWSER_WIDTH = 640;

export const NUMBER_OF_LESSEE_USERS = 350;
export const NUMBER_OF_LESSOR_USERS = 50;
export const NUMBER_OF_TOTAL_USERS = NUMBER_OF_LESSEE_USERS + NUMBER_OF_LESSOR_USERS;

export const NUMBER_OF_LESSEE_USERS_KOREAN_VERSION = `${NUMBER_OF_LESSEE_USERS}만`;
export const NUMBER_OF_LESSOR_USERS_KOREAN_VERSION = `${NUMBER_OF_LESSOR_USERS}만`;
export const NUMBER_OF_TOTAL_USERS_KOREAN_VERSION = `${NUMBER_OF_TOTAL_USERS}만`;

export const HEADER_VISIBLE_SCROLL_Y_PX = 30;

export const ADMIN_BOTTOM_NAV_HEIGHT = 79.08;
